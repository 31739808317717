<template>
  <div>
    <main
      id="main"
      class="min-h-screen"
      :class="
        isFlyOutActive
          ? '-translate-x-full lg:-translate-x-flyOutWidth transition-transform duration-300'
          : 'transition-transform duration-300'
      "
    >
      <Sidenav />
      <MenuOverlay />
      <!-- <WebcamOverlay /> -->
      <!-- <LoadingAnimation class="load-anim" /> -->

      <component
        v-if="sanityPage && showComponent"
        :key="sanityPage"
        :is="renderDocumentComponent(sanityPage)"
        :page="sanityPage"
      />
    </main>
    <ContactOverlay />
  </div>
</template>

<script setup lang="ts">
import Hotjar from '@hotjar/browser';
import { useSettingsStore } from '~/stores/useSettingsStore';
import { useUiStore } from '~/stores/useUiStore';
import { storeToRefs } from 'pinia';
import { usePageStore } from '~/stores/usePageStore';

// Hotjar config
const siteId = 3655550;
const hotjarVersion = 6;

const showComponent = ref(false);
onMounted(() => {
  Hotjar.init(siteId, hotjarVersion);
  setTimeout(() => {
    showComponent.value = true;
  }, 900); // delay to avoid page showing before transition
});

// Stores
const uiStore = useUiStore();
const settingsStore = useSettingsStore();
const { siteParts } = storeToRefs(settingsStore);

// Composables
const { $fullpath } = useSiteSettings();
// Computed properties
const isFlyOutActive = computed(() => uiStore.ui.isFlyOutActive);

const {
  query: { preview },
  path,
  fullPath
} = useRoute();

// please forgive me for this
// we force the season cookie to be set to the current season set in Sanity
// This cover the case when use switch to the opposite season, and the go back to homepage.
if (path === '/' || path === '/en' || path === '/fr') {
  const cookieSeason = useCookie('season');
  cookieSeason.value = siteParts.value?.header.season;
}

const sanityPage = ref<SanityPage | null>(null);

if (preview && process.env.NODE_ENV === 'production') {
  sanityPage.value = await useFetchPreviewPage();
} else {
  // We have to make a clear distinction between normal pages
  // and the event pages that we get from the external api source.
  // In Sanity, in the settings option, you can set the parent event page,
  // that will be used to render the detail page event.
  const eventPageFullPath = siteParts.value && $fullpath(siteParts.value?.general?.eventPage?._ref);
  // Edge case: the page to load is the event page itself
  // It contains the fullpath, but has to match perfecly
  const isEventPage = fullPath === `${eventPageFullPath}`;

  if (isEventPage) {
    sanityPage.value = await useFetchPage();

    // SEO
    if (sanityPage) {
      useSEOEventsMetatags(sanityPage.value);
      usePrefetchMetatags();
      useBaseMetatags(sanityPage);
      useEventsJsonLdMeta(sanityPage.value);
    }
  } else {
    if (eventPageFullPath && fullPath.includes(eventPageFullPath)) {
      const eventPageSlug = path.replace(`${eventPageFullPath}`, '').slice(1);
      const {
        public: { eventApiUrl, baseURL }
      } = useRuntimeConfig();
      const { locale } = useI18n();
      const apiUrl =
        locale.value === 'de'
          ? 'https://developers.estm.ch/hotel-waldhaus-sils/events'
          : 'https://developers.estm.ch/en/hotel-waldhaus-sils/events';
      const eventDetailPageUrl = `${apiUrl}/${eventPageSlug}`;

      try {
        const { data: event } = await useFetch<EventResponse>(eventDetailPageUrl);

        sanityPage.value = {
          _type: 'event',
          event: event.value?.content[0],
          seoMetadata: {
            title: event.value?.content[0].data.item.title || 'Event in Waldhaus Sils',
            description: event.value?.content[0].data.item.description || 'Event in Waldhaus Sils',
            image: event.value?.content[0].data.item.images.list[0].publicUrlForCrop,
            noFollow: false,
            noIndex: false
          },
          url: {
            fullPath: `${baseURL}/${eventPageSlug}`
          }
        };
      } catch (error) {
        createError({
          fatal: true,
          statusCode: error?.status ?? 500,
          message: error instanceof Error ? error.message : 'An error occurred',
        })
      }

      // SEO
      if (sanityPage) {
        useSEOEventsMetatags(sanityPage.value);
        usePrefetchMetatags();
        useBaseMetatags(sanityPage);
        useEventsJsonLdMeta(sanityPage.value);
      }
    } else {
      // Normal page fetch
      sanityPage.value = await useFetchPage();

      if (!preview && sanityPage.value) {
        const { setPageTranslations } = usePageStore();
        setPageTranslations(sanityPage.value);
        useSEOMetatags(sanityPage.value);
        usePrefetchMetatags();
        useBaseMetatags(sanityPage.value);
        useJsonLdMeta(sanityPage.value);

        if (sanityPage.value.translations) {
          useSEOAlternatesLinks(sanityPage.value);
        }
      }
    }
  }
}

// redirect to 404 if page document not found
if (!sanityPage.value) {
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found', fatal: true });
}

const renderDocumentComponent = (documentComponent: any) => {
  let component;
  const isPageDocumentType = documentComponent._type === 'page';
  const isRoomDocumentType = documentComponent._type === 'room';
  const isRestaurantDocumentType = documentComponent._type === 'restaurant';
  const isPackageDocumentType = documentComponent._type === 'package';
  const isActivityDocumentType = documentComponent._type === 'activity';
  const isEventDocumentType = documentComponent._type === 'event';

  switch (true) {
    case isPageDocumentType:
      component = resolveComponent('Page');
      break;
    case isRoomDocumentType:
      component = resolveComponent('Room');
      break;
    case isRestaurantDocumentType:
      component = resolveComponent('Restaurant');
      break;
    case isPackageDocumentType:
      component = resolveComponent('Package');
      break;
    case isActivityDocumentType:
      component = resolveComponent('Activity');
      break;
    case isEventDocumentType:
      component = resolveComponent('Event');
      break;
    default:
      component = resolveComponent('Page');
  }

  return component;
};
</script>
